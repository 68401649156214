<template>
  <div class="form-group">

    <div class="d-flex">
      <label v-if="label" class="form-label">{{label}}</label>
      <span class="ml-auto badge badge-sm rounded-bottom-0" :class="{'bg-primary': lgt < (max/3)*2, 'bg-warning': lgt >= (max/3)*2}" v-if="max">{{lgt}}/{{max}}</span>
    </div>

    <div class="input-wrap">

      <textarea class="form-input" aria-label="textarea"
                :class="style"
                :rows="rows || 10"
                :placeholder="placeholder"
                v-model="_value"
                :maxlength="max"
                @click="$emit('click', $event)"
                @keyup="$emit('keyup', $event)"
                @keydown="onKeyDown"
                @keypress="$emit('keypress', $event)"
                @change="$emit('change', $event)"
                @blur="$emit('blur', $event)"
                @focus="$emit('focus', $event)"></textarea>
    </div>

    <span v-if="help !== undefined" class="form-help">{{help}}</span>
  </div>
</template>

<script>
  export default {
    props: {
      placeholder: [String, Number],
      label: [String, Number],
      help: {
        type: [String, Number],
        default: undefined
      },
      rows: [String, Number],
      max: Number,
      icon: String,
      disableNewline: Boolean,
      value: [String, Number],
      valid: {
        type: Boolean,
        default: true
      },
      warning: {
        type: Boolean,
        default: false
      },
      success: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      _value: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      style() {
        return {
          'form-success': this.success,
          'form-warning': this.warning,
          'form-danger': !this.valid,
          'form-primary': this.valid
        }
      },
      lgt() {
        return this._value ? this._value.length : 0
      }
    },
    methods: {
      onKeyDown(e) {
        if(this.disableNewline && e.keyCode === 13)
          e.preventDefault();

        this.$emit('keydown', e);
      }
    }
  }
</script>
