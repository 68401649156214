var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c("div", { staticClass: "d-flex" }, [
      _vm.label
        ? _c("label", { staticClass: "form-label" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.max
        ? _c(
            "span",
            {
              staticClass: "ml-auto badge badge-sm rounded-bottom-0",
              class: {
                "bg-primary": _vm.lgt < (_vm.max / 3) * 2,
                "bg-warning": _vm.lgt >= (_vm.max / 3) * 2
              }
            },
            [_vm._v(_vm._s(_vm.lgt) + "/" + _vm._s(_vm.max))]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "input-wrap" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm._value,
            expression: "_value"
          }
        ],
        staticClass: "form-input",
        class: _vm.style,
        attrs: {
          "aria-label": "textarea",
          rows: _vm.rows || 10,
          placeholder: _vm.placeholder,
          maxlength: _vm.max
        },
        domProps: { value: _vm._value },
        on: {
          click: function($event) {
            return _vm.$emit("click", $event)
          },
          keyup: function($event) {
            return _vm.$emit("keyup", $event)
          },
          keydown: _vm.onKeyDown,
          keypress: function($event) {
            return _vm.$emit("keypress", $event)
          },
          change: function($event) {
            return _vm.$emit("change", $event)
          },
          blur: function($event) {
            return _vm.$emit("blur", $event)
          },
          focus: function($event) {
            return _vm.$emit("focus", $event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm._value = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _vm.help !== undefined
      ? _c("span", { staticClass: "form-help" }, [_vm._v(_vm._s(_vm.help))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }